import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { RESELLER } from 'src/app/shared/utility/global.constants';

@Injectable()
export class ResellerInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headers = request.headers.set(
      RESELLER.header,
      'ad1401fcce5df1fc12be071e9efe149505323b20e8cf9493e94150096e4549ad'
    );

    const clone = request.clone({ headers });
    return next.handle(clone);
  }
}
