import { NgModule } from '@angular/core';
import { canActivate, redirectLoggedInTo, redirectUnauthorizedTo } from '@angular/fire/auth-guard';
import { RouterModule, Routes } from '@angular/router';

import { eventGuard } from './core/guards/event.guard';
import { profileGuard } from './core/guards/profile.guard';
import { vipRoomGuard } from './core/guards/vip-room.guard';

const redirectLoggedInToHome = () => redirectLoggedInTo(['select-profile']);
const redirectUnauthorizedToSignIn = () => redirectUnauthorizedTo(['sign-in']);

const routes: Routes = [
  { path: '', redirectTo: 'sign-in', pathMatch: 'full' },
  {
    path: 'sign-up',
    loadChildren: () =>
      import('./core/components/sign-up/sign-up.module').then(m => m.SignUpModule),
    ...canActivate(redirectLoggedInToHome)
  },
  {
    path: 'sign-in',
    loadChildren: () =>
      import('./core/components/sign-in/sign-in.module').then(m => m.SignInModule),
    ...canActivate(redirectLoggedInToHome)
  },
  {
    path: 'forgot-password',
    loadChildren: () =>
      import('./core/components/forgot-password/forgot-password.module').then(
        m => m.ForgotPasswordModule
      )
  },
  {
    path: 'select-profile',
    loadChildren: () =>
      import('./features/components/select-profile/select-profile.module').then(
        m => m.SelectProfileModule
      ),
    ...canActivate(redirectUnauthorizedToSignIn)
  },
  {
    path: 'reset-password',
    loadChildren: () =>
      import('./core/components/reset-password/reset-password.module').then(
        m => m.ResetPasswordModule
      )
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./features/components/dashboard/dashboard.module').then(m => m.DashboardModule),
    ...canActivate(redirectUnauthorizedToSignIn)
  },
  {
    path: 'setting-devices',
    loadChildren: () =>
      import('./features/components/setting-devices/setting-devices.module').then(
        m => m.SettingDevicesModule
      ),
    ...canActivate(redirectUnauthorizedToSignIn),
    canActivate: [eventGuard]
  },
  {
    path: 'snap-photo',
    loadChildren: () =>
      import('./features/components/snap-photo/snap-photo.module').then(m => m.SnapPhotoModule),
    ...canActivate(redirectUnauthorizedToSignIn),
    canActivate: [eventGuard]
  },
  {
    path: 'vip-room',
    loadChildren: () =>
      import('./features/components/vip-room/vip-room.module').then(m => m.VipRoomModule),
    ...canActivate(redirectUnauthorizedToSignIn),
    // canActivate: [vipRoomGuard]
  },
  { path: 'event-preview', loadChildren: () => import('./features/components/event-preview/event-preview.module').then(m => m.EventPreviewModule) }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
