import { collection } from "firebase/firestore";

export const ENDPOINTS = {
  verify: '/auth/verify',
  reset_password: '/auth/reset-password'
};

export const ADDED_TIME = {
  collection: 'addedTimes'
}

export const EVENT = {
  collection: 'events'
};

export const GUEST = {
  collection: 'guests'
};

export const CALL = {
   collection: 'calls',
   endpoint: 'call/'
 }
 export const PRESENTATIONS = {
   endpoint: 'presentations/'
 }

export const ORGANIZATION = {
  collection: 'organizations',
  endpoint: '/organizations',
  header: 'Linkroom-Organization',
  logoPath: '{0}/logo.{1}',
  logoPathNoExt: '{0}/logo',
  permissions: {
    create: 'create_organization',
    delete: 'delete_organization',
    deleteBulk: 'delete_bulk_organization',
    get: 'get_organization',
    list: 'list_organizations',
    update: 'update_organizations'
  },
  route: 'organizations'
};

export const PRIVACY_POLICY_URL = 'https://www.linkroom.com/privacy-policy';

export const PROFILE = {
  endpoint: '/vip-profiles',
  route: 'vip-profiles'
};

export const QUEUE = {
  endpoint: '/queue'
};

export const RESELLER = {
  collection: 'resellers',
  current: 'linkroomv2-admin-dev.web.app',
  endpoint: '/resellers',
  header: 'Linkroom-Reseller',
  key: 'reseller',
  linkroom: 'ad1401fcce5df1fc12be071e9efe149505323b20e8cf9493e94150096e4549ad',
  permissions: {
    create: 'create_reseller',
    delete: 'delete_reseller',
    deleteBulk: 'delete_bulk_reseller',
    get: 'get_reseller',
    list: 'list_resellers',
    update: 'update_resellers'
  },
  route: 'resellers'
};

export const TERMS_OF_USE_URL = 'https://osufandevwedev.wpenginepowered.com/terms-of-use/';

export const USER = {
  endpoint: '/vips',
  route: 'users'
};

export const VIP = {
  collection: 'vips'
};

export const VIP_PROFILE = {
  collection: 'vip-profiles'
};
