import { inject, Injectable } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class EventGuardService {
  constructor(private router: Router) {}

  createUrlTree(commands: any[]) {
    return this.router.createUrlTree(commands);
  }
}

export const eventGuard: CanActivateFn = async (route, state) => {
  const eventGuardService = inject(EventGuardService);

  const event = localStorage.getItem('selectedEvent');

  if (!event) return eventGuardService.createUrlTree(['/dashboard']);

  return true;
};